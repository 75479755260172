var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "form-rincian-audit",
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary",
            "disabled": _vm.selectedStocks.length < 1
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Pilih Aset")])];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "filter": _vm.filter,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "fields": _vm.fields,
      "items": _vm.penyimpanans
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedStocks,
            callback: function ($$v) {
              _vm.selectedStocks = $$v;
            },
            expression: "selectedStocks"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(jenis)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.asset ? _c('div', [_vm._v(" " + _vm._s(item.asset.jenis == 1 ? 'TETAP' : 'BPH') + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.asset ? item.asset.nama : '') + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.asset ? item.asset.kode : '') + " ")];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(item.asset.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('span', [_vm._v(_vm._s(item.stok))])];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : '') + " ")];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }